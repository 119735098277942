import * as React from "react";
import { graphql, Link, PageProps } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faInfoCircle,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { Job, getJobPageSlug, getJobListPageSlug } from "../types/job";
import { getAbsolutePageUrl, getPagingRangeArray } from "../utils";
import moment from "moment";
import SEO from "components/seo";
import Ad from "components/ad";

interface JobListPageProps extends PageProps {
  data: {
    allJob: {
      edges: [
        {
          node: Job;
        }
      ];
    };
  };

  pageContext: {
    limit: number;
    skip: number;
    totalPages: number;
    currentPage: number;
    sortField: string;
    sortOrder: string;
  };
}

const JobListPage = ({ data, location, pageContext }: JobListPageProps) => {
  const jobList = data.allJob.edges;
  const hasJobs = Number(jobList.length) > 0;
  const hasPagination = pageContext.totalPages > 1;
  const paginationArray = getPagingRangeArray(
    pageContext.currentPage,
    1,
    pageContext.totalPages,
    5
  );

  const pageTitle =
    "Vagas" +
    (pageContext.currentPage > 1 ? `: Página ${pageContext.currentPage}` : "");

  return (
    <React.Fragment>
      <SEO
        title={pageTitle}
        canonicalUrl={getAbsolutePageUrl(location.pathname)}
      />

      <h1 className="text-center card-title mb-3">{pageTitle}</h1>

      {!hasJobs && (
        <div className="row justify-content-center">
          <div className="col-md-8 col-xl-8 align-self-center py-3">
            <div className="text-white bg-info border rounded border-0 p-3 p-lg-4">
              <p className="text-center mb-0">
                <FontAwesomeIcon icon={faInfoCircle} className="fs-5 me-3" />
                Desculpe, não encontramos nenhuma vaga. &#128533;
              </p>
            </div>
          </div>
        </div>
      )}

      <div className="d-none d-lg-block">
        <div className="d-grid gap-3">
          {jobList.map(({ node: job }) => {
            return (
              <div
                key={job.uid}
                className="card border-light border rounded border-1 shadow-sm"
              >
                <div className="card-body jobListItem">
                  <h5 className="card-title text-clamp-line-2">{job.title}</h5>
                  <h6 className="text-muted card-subtitle mb-2">
                    {moment(parseInt(job.publishDate ?? "")).format("LLL")}
                    {/* • Veja mais:&nbsp;
                              <Link className="link-secondary" to="/cat/">
                                Administrativo
                              </Link>
                              ,&nbsp;
                              <Link className="link-secondary" to="/cat/">
                                Home Office
                              </Link> */}
                  </h6>
                  <p className="card-text text-clamp-line-2 m-0">
                    {job.description?.substring(0, 300)}
                    <Link to={getJobPageSlug(job)} className="stretched-link">
                      Leia mais
                    </Link>
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="d-block d-lg-none">
        <div className="d-grid gap-0">
          {jobList.map(({ node: job }: { node: Job }, index: number) => {
            return (
              <div
                key={job.uid}
                className="card border rounded border-0 text-decoration-none"
              >
                <div
                  className={
                    "card-body jobListItem border rounded-0 border-2 border-primary p-0 py-3 py-sm-4 border-start-0 border-end-0 border-top-0" +
                    (index < jobList.length - 1 ? "" : " border-bottom-0")
                  }
                >
                  <h5 className="card-title text-clamp-line-2 mb-2">
                    {job.title}
                  </h5>
                  <p className="card-text text-clamp-line-2">
                    {job.description?.substring(0, 300)}
                  </p>
                  <h6 className="text-muted d-flex justify-content-between align-items-center card-subtitle text-clamp-line-2">
                    {moment(parseInt(job.publishDate ?? "")).format("LLL")}
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className="fs-1 text-primary"
                    />
                  </h6>
                  <Link
                    to={getJobPageSlug(job)}
                    className="stretched-link"
                  ></Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <Ad>
        <div
          className="text-center bg-danger d-flex justify-content-center align-items-center"
          style={{ height: 90, width: "100%" }}
        >
          <h4 className="text-center text-white">
            Espaço para propaganda (Ads)
          </h4>
        </div>
      </Ad>

      {/* Pagination */}
      {hasPagination && (
        <nav>
          <ul className="pagination pagination-lg flex-wrap justify-content-center m-0">
            {
              // Previous
              <li
                className={
                  "page-item mb-2" +
                  (pageContext.currentPage === 1 ? " disabled" : "")
                }
              >
                <Link
                  className="page-link"
                  aria-label="Previous"
                  to={getJobListPageSlug(pageContext.currentPage - 1)}
                >
                  <span aria-hidden="true">Anterior</span>
                </Link>
              </li>
            }

            {
              // First
              paginationArray[0] >= 2 && (
                <React.Fragment>
                  <li className="page-item">
                    <Link
                      className="page-link"
                      aria-label="Next"
                      to={getJobListPageSlug(1)}
                    >
                      <span aria-hidden="true">1</span>
                    </Link>
                  </li>
                  {paginationArray[0] >= 3 && (
                    <li className="page-item disabled">
                      <Link className="page-link" aria-label="Next" to="#">
                        <span aria-hidden="true">...</span>
                      </Link>
                    </li>
                  )}
                </React.Fragment>
              )
            }

            {
              // Numbers range
              paginationArray.map((p, i) => {
                return (
                  <li
                    key={i}
                    className={
                      "page-item" +
                      (p === pageContext.currentPage ? " active" : "")
                    }
                  >
                    <Link className="page-link" to={getJobListPageSlug(p)}>
                      {p}
                    </Link>
                  </li>
                );
              })
            }

            {
              // Last
              paginationArray[paginationArray.length - 1] <=
                pageContext.totalPages - 1 && (
                <React.Fragment>
                  {paginationArray[paginationArray.length - 1] <=
                    pageContext.totalPages - 2 && (
                    <li className="page-item disabled">
                      <Link className="page-link" aria-label="Next" to="#">
                        <span aria-hidden="true">...</span>
                      </Link>
                    </li>
                  )}
                  <li className="page-item">
                    <Link
                      className="page-link"
                      aria-label="Next"
                      to={getJobListPageSlug(pageContext.totalPages)}
                    >
                      <span aria-hidden="true">{pageContext.totalPages}</span>
                    </Link>
                  </li>
                </React.Fragment>
              )
            }

            {
              // Next
              <li
                className={
                  "page-item" +
                  (pageContext.currentPage >= pageContext.totalPages
                    ? " disabled"
                    : "")
                }
              >
                <Link
                  className="page-link"
                  aria-label="Next"
                  to={getJobListPageSlug(pageContext.currentPage + 1)}
                >
                  <span aria-hidden="true">Próxima</span>
                </Link>
              </li>
            }
          </ul>
        </nav>
      )}

      <Ad>
        <div
          className="text-center bg-secondary d-flex justify-content-center align-items-center"
          style={{ height: 120, width: "100%" }}
        >
          <h4 className="text-center text-white">
            Espaço para propaganda (Ads)
          </h4>
        </div>
      </Ad>
    </React.Fragment>
  );
};

export default JobListPage;

export const jobListPageQuery = graphql`
  query jobListPageQuery(
    $skip: Int!
    $limit: Int!
    $sortField: JobFieldsEnum!
    $sortOrder: [SortOrderEnum]!
  ) {
    allJob(
      sort: { fields: [$sortField], order: $sortOrder }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          uid
          title
          publishDate
          description
        }
      }
    }
  }
`;
